 @font-face {
 	font-family: SpeziaCompleteVariableUpright;
 	font-stretch: 50% 200%;
 	font-weight: 1 1000;
 	src: url('./fonts/Spezia_Web_Complete/VariableFont/Complete/SpeziaCompleteVariableUprightWeb.woff2') format('woff2');
 }

 @font-face {
 	font-family: SpeziaCompleteVariableItalic;
 	font-stretch: 50% 200%;
 	font-weight: 1 1000;
 	src: url('./fonts/Spezia_Web_Complete/VariableFont/Complete/SpeziaCompleteVariableItalicWeb.woff2') format('woff2');
 }
